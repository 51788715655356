import Vue from 'vue';
import VueRouter from 'vue-router';
import OnePage from '@/views/OnePage/Index.vue';
import InstantMoney from '@/views/Products/InstantMoney.vue';
import AFR from '@/views/Products/AFR.vue';
import InfoSafety from '@/views/Products/InfoSafety.vue';
import Thanks from '@/views/ThanksPage/Thanks.vue';
import Tender from '@/views/Tender/Tender.vue';
import Blog from '@/views/Blog/Blog.vue';
import ScopeErp from '@/views/ScopeErp/ScopeErp.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'OnePage',
        components: {
            OnePage
        }
    },
    {
        path: '/products/instant-money',
        name: 'InstantMoney',
        components: {
            InstantMoney
        },
        meta: { transitionName: 'slide' }
    },
    {
        path: '/products/azonnali-fizetési-rendszer',
        name: 'AFR',
        components: {
            AFR
        },
        meta: { transitionName: 'slide' }
    },
    {
        path: '/products/információbiztonsági-szolgáltatás',
        name: 'InfoSafety',
        components: {
            InfoSafety
        },
        meta: { transitionName: 'slide' }
    },
    {
        path: '/koszonjuk',
        name: 'Thanks',
        components: {
            Thanks
        },
        meta: { transitionName: 'slide' }
    },
    {
        path: '/tender',
        name: 'Tender',
        components: {
            Tender
        },
        meta: { transitionName: 'slide' }
    },
    {
        path: '/blog',
        name: 'Blog',
        components: {
            Blog
        },
        meta: { transitionName: 'slide' }
    },
    {
        path: '/scope',
        name: 'ScopeErp',
        components: {
            ScopeErp
        },
        meta: { transitionName: 'slide' }
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: 'is-active',
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return {
                selector: to.hash
            };
        } else {
            return { x: 0, y: 0 };
        }
    }
});

export default router;
