<template>
	<div>
		<NavBar />
		<section class="infoSafety-section">
			<b-container>
				<b-row>
					<b-col>
						<h2>INFORMÁCIÓBIZTONSÁGI SZOLGÁLTATÁS</h2>
						<p>
							Megbízónk működési képessége kerülhet veszélybe, ha adatainak tárolása, kezelése nem biztonságos vagy
							váratlan események történnek a cég életében. Ennek elkerülésére ad tökéles megoldást a cégünk által
							nyújtott információ biztonsági rendszerünk. CISA és CISM minősítéssel rendelkező szakértőink Megbízónk
							igényei alapján segítenek a magyar- és nemzetközi szabványok szerinti előírások értelmezésében, az IBIR
							(Információ Biztonsági Irányítási Rendszer) kialakításában és a szabályzatok kidolgozásában. Mindezeket
							mindig a meglévő IT infrastruktúrához igazodva, Megbízónk cégére szabva alakítjuk ki, egy magasabb
							biztonsági szint elérése érdekében. Az IBIR átfogja és szabályozza a cég teljes informatikai
							tevékenységét. A rendszer részei: szervezeti felépítés és jogosultság rendszer kezelése, a biztonságos
							működtetés szabályzatai, a felelősségi körök egyértelmű meghatározása, a mentés és archiválás
							szabályozása, a folyamatok megvalósításának eljárásai.
						</p>
						<h3>Megvalósítás szakaszai</h3>
						<ol>
							<li>
								Helyzetfelmérés: igények, standardok felmérése, üzleti hatáselemzés, IT rendszer elemzése, adatvagyon
								leltár készítése, kockázatok feltárása
							</li>
							<li>
								Helyzetértékelés: standardok, erőforrások, adatvagyon leltár, fenyegetések, kockázatelemzés kiértékelése
							</li>
							<li>
								Védelemszervezés: biztonsági stratégia, biztonsági szabályzat, üzletmenet folytonosság (BCP)- és
								katasztrófa elhárítási terv (DRP), működési eljárások kialakítása és oktatása
							</li>
						</ol>
						<h3>IBIR elkészítésétől és bevezetésétől várt eredmények</h3>
						<p>
							Működési adatlapok-, IT rendszer- és szolgáltatáskatalógus-, erőforrások és adatvagyon lista-,
							kockázatelemzési jelentés-, kockázatkezelési javaslatok-, munkavállalók szerepköri kódexe-, szabályzatok-,
							eljárások elkészítése, technikai megoldások kivitelezése. A fent leírt szolgáltatásokat biztosítjuk
							Megbízóink részére az államigazgatási-, pénzügyi-, és agrár szektorban szerzett tapasztalatunkkal.
						</p>
					</b-col>
				</b-row>
				<b-row class="justify-content-center align-items-center footer">
					<b-col cols="6">
						<b-img :src="imgLogo" class="footer__img" />
					</b-col>
					<b-col cols="6">
						<p>Copyright © {{ yearToday }} Techron Kft.</p>
						<a :href="`${publicPath}documents/adatkezelesi-tajekoztato.pdf`" target="_blank" class="link-privacy"
							>Adatkezelési Tájékoztató</a
						>
					</b-col>
				</b-row>
			</b-container>
		</section>
	</div>
</template>

<script>
import NavBar from './components/NavBar';

export default {
	data() {
		return {
			publicPath: process.env.BASE_URL,
			imgLogo: require('@/assets/images/logo.png'),
			yearToday: null,
		};
	},
	created() {
		this.actualYear();
	},
	methods: {
		actualYear() {
			var d = new Date();
			var n = d.getFullYear();
			this.yearToday = n;
		},
	},
	components: {
		NavBar,
	},
};
</script>

<style lang="scss" scoped>
h3 {
	font-size: 1.55rem;
	text-align: start;
	margin: 1em 3em !important;
}
@media only screen and (max-width: 480px) {
	p {
		margin: 2em 0em !important;
		text-align: justify;
	}
	h2 {
		font-size: 1.5em;
	}

	ol {
		text-align: start !important;
		margin: 2em 1em !important;
	}
	.footer p {
		margin: 0px 0px !important;
	}
	h3 {
		margin: 0px 0px !important;
		font-size: 1.5em !important;
	}
	li {
		margin: 1em 0px;
	}
}
li {
	margin: 1em 0px;
}

p {
	margin: 2em 5em;
	text-align: justify;
}
ol {
	text-align: justify;
	margin: 2em 5em;
}
h3 {
	margin: 2em;
}
.link-privacy {
	color: #2980b9;
}
.link-privacy:hover {
	color: #2980b9;
	text-decoration: none;
}
.footer__img {
	font-size: 0.5em;
}
.footer {
	margin-top: 5em;
}
.footer p {
	margin: 0;
	text-align: center;
}
.infoSafety-page {
	padding-top: 11em;
	background: #131421;
	min-height: 100vh;
}
.infoSafety-section .container {
	background-color: #1f2131;
	color: #fff;
	padding: 20px;
	border-radius: 15px;
	font-size: 15px;
}

.infoSafety-section .container:last-of-type p {
	margin-bottom: 0;
}
</style>
