<template>
	<div class="maintenance">
		<h1>Techron is currently down for maintenance.</h1>
		<h2>We expect to be back soon.Thanks for your patience.</h2>
	</div>
</template>

<script>
export default {
	name: 'OnePage',
};
</script>

<style scoped lang="scss">
.maintenance {
	color: white;
	padding: 15%;
}
</style>
