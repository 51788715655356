<template>
	<div>
		<h2>Köszönjük a kapcsolatfelvételt, hamarosan az egyik kollégánk felveszi önnel a kapcsolatot.</h2>
		<button class="btn btn__send"><router-link to="/">Vissza a főoldalra</router-link></button>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.thanks-page {
	background: #131421;
	min-height: 100vh;
}
h2 {
	padding: 10%;
	font-size: 8em !important;
	color: white !important;
}

button a {
	color: white;
}
</style>
