<template>
	<div>
		<NavBar />
		<section class="instant-money-section">
			<b-container class>
				<b-row>
					<b-col>
						<h1>Instant Money</h1>
						<h2>
							INTEGRÁLT HITELINTÉZETI SZOFTVERRENDSZER
						</h2>
						<h3>Általános rendszerjellemzők</h3>
						<p>
							Az instantMoney multidevizás és teljes mértékben integrált hitelintézeti szoftverrendszer. Egységes
							törzsadattárral rendelkezik, alapdevizában és bármely devizában is. Rugalmasan és árnyaltan
							paraméterezhető, a különböző változások végrehajtása saját hatáskörben, szolgáltatói beavatkozás nélkül
							elvégezhető. Használatával elvégezhető az ügyfelek kiszolgálása minden üzletág tekintetében. Az összes
							front office és back office tevékenység végrehajtására alkalmas, és nem igényel külön
							szatellitrendszereket. Az integrációnak köszönhetően az instantMoney-ban megtalálható összes logikai modul
							között biztosított az átjárás. Többszintű adatvédelem és hozzáférési jogosultság-ellenőrzés lehetséges. Az
							instantMoney nagyon erősen workpool-támogatott rendszer. Az instantMoney felhasználói felületén az egyes
							tevékenységek menürendszeren keresztül érhetők el. Az egyes modulok csak logikai modulok így online
							kommunikálnak egymással és látják egymás adatait.
						</p>
						<h3>Interface</h3>

						<ul>
							<li>integrált interfacek</li>
							<li>
								szabványos kommunikációs formák támogatása (JSON, XML), (HTTPS, file alapú, adatbázis alapú, webservice)
							</li>
							<li>szankció listák</li>
							<li>core rendszer elé helyezett kommunikációs réteg</li>
							<li>Kártya interface</li>
							<li>Netbank interface</li>
						</ul>
						<h3>Partner</h3>
						<p>
							Központi ügyféladatbázis. Az ügyfelekről a 2017. évi LIII. törvényben meghatározott kötelező adatkör
							(minimumadatkör) megadása szükséges számlanyitáshoz, de a belső szabályzatban meghatározott esetekben
							további adatok rögzítése paraméterrel szabályozható. Ügyfélátvilágítási szintek és partnercsoportok
							kezelése
						</p>
						<h3>Pénztár</h3>
						<p>
							Lehetséges egy- vagy kétpontos működés, címletezés vagy címletezés nélküli megoldás. Több pénztárbizonylat
							összevonásának, valamint a valutakezelésnek a lehetősége is adott, akár egyedi árfolyamon is. A rendszer
							megkülönböztet házi és ügyfélpénztárt. Paraméterezhető pénztár bizonylat nyomtatása.
						</p>
						<h3>Bankszámla</h3>
						<p>
							A deviza- és forintszámla-vezetés egyaránt lehetséges a rendszerben. Bankszámlakivonatok bármilyen nyelven
							elkészíthetők az instantMoney rendszerből. A Bankszámla modul részét képezik a következők: tartós
							megbízások, eseti forint- és devizaátutalások, bankkártyák kezelése, felhatalmazások, tartós befektetések
							kezelése, számlatitkosítás, online kártyakapcsolat, PEK-jóváírások automatikus beolvasása, postai
							kifizetési utalvány kezelése, fedezeti zárolások kezelése.
						</p>
						<h3>Betét</h3>
						<p>
							Ezek lehetnek az ún. hagyományos könyves betétek, valamint lehetnek egyedi szerződések, nyomtatványok,
							illetve fix címletes betétek. A termékek elhelyezhetők közvetlenül pénztáron vagy folyószámlán keresztül.
						</p>
						<h3>Folyószámla mellett lekötött betét</h3>
						<p>
							A Lekötött betét modul a forintban és devizában elhelyezhető megtakarításokat egyaránt kezeli. Az
							elhelyezés minden esetben az ügyfél folyószámlájáról történhet. A termékek széles skáláját kezeli a
							rendszer mind a kamatozás típusára (kamatosztó, bázistól függő kamatbeállítás), módjára (tőkésedő/nem
							tőkésedő, lekötéskori/lejáratkori kamatfizetés, idősávhoz és/vagy összegsávhoz kötött, idősáv- vagy
							összegsávtartományos kamatozás, lekötéskori, lekötési napvégi, lejáratkori kamatfizetés), az elhelyezés
							időtartamára (egyszeri/ciklikus, napos/hónapos futamidők), mind az egyéb finomhangolásokra
							(minimum/maximum elhelyezhető összeg, ciklushoz vagy összegsávhoz kötött kamatprémium, ügyletenkénti
							egyedi kamatozás, egységes vagy idősávokhoz köthető feltörési kamat) vonatkozólag.
						</p>
						<h3>Hitel</h3>
						<p>
							Az instantMoney a hiteltermékek teljes skáláját kezelni tudja, ami kiterjed a devizahitelek, illetve a
							devizaalapú hitelek kezelésére is. Az ügyfélcsoportok kezelése teljes körű, a vállalkozások méretének
							meghatározása automatikusan, a törvényben meghatározottak szerint történik. A partnerek adósminősítési
							lehetősége a Hitel modul integrált része. A hitelezés folyamatát a kérelem befogadásától a hitel
							visszafizetéséig vagy a felmondott hitelek végső teljesítéséig, ezen túlmenően a leírt hitelek analitikus
							nyilvántartásáig kezeli. Biztosítéki nyilvántartása teljes körű, analitikusan és főkönyvileg
							nyilvántartott. A banki hitelezést teljes mélységében átfogja, egységes egészet képezve a többi
							üzletággal; a különböző automatizmusok működése mellett lehetőséget biztosít az egyediségeknek is. A
							hitelszerződésekhez kapcsolódó személyeket és a fedezeteket, valamint a kapcsolt szerződéseket
							teljeskörűen nyilvántartja. A cenzúrabizottsági döntéseket szerződésszinten nyilvántartja. A paraméterezés
							hangolhatósága révén széles körű szabadságot teremt a törlesztések módjára vonatkozóan (fizetéskönnyítési
							csomagok kezelése, átstrukturálás stb.).
						</p>
						<h3>Árfolyamok kezelése</h3>
						<p>
							A mindennapi árfolyamokat árfolyamtáblákban tartjuk nyilván. Az árfolyamtáblák szabadon bővíthetők, így a
							bank az árfolyampolitikájának megfelelően minden érvényes árfolyamát nyilván tudja tartani a rendszerben
						</p>
						<h3>Dokumentumiktatás</h3>
						<p>
							A rendszer a bank bejövő és kimenő küldeményeinek nyilvántartására szolgál. Tipizálhatók a
							dokumentumtípusok, pl. sima levél, fax, szerződés stb. A rendszer végig követi a dokumentum életét a
							beérkezéstől/kiküldéstől a selejtezésig/irattárba helyezésig.
						</p>
						<h3>Szigorú számadású bizonylatok</h3>
						<p>
							A rendszeren belül lehetőség van arra, hogy a szigorú sorszámozású bizonylatokat nyilván lehessen tartani.
							Tipizálhatók a dokumentumtípusok. A rendszerben végig követhető a bizonylatok életét a bevételezéstől a
							selejtezésig/ügyfélnek történő kiadásig.
						</p>
						<h3>Digitalizálás</h3>
						<p>
							Képformátumú fájlokat lehet az instantMoney rendszerben a rögzített analitikus tételekhez kapcsolni, és az
							analitikus tételnél lekérdezni
						</p>
						<h3>Pénzügy</h3>
						<p>
							A Pénzügyi modul teljes egészében lefedi egy gazdasági szervezetnél a pénzügyi tevékenységek körét.
							Alkalmas a cég számláinak (bejövő és kimenő), tárgyi eszközeinek, értékpapírjainak, utalásainak és
							különböző pénzmozgásainak követésére és nyilvántartására, házi pénztárak kezelésére. A rendszerben
							lehetőség van a kontírozási és könyvelési tevékenységek teljes körű elvégzésére. A modul alk
						</p>
						<h3>Tárgyieszköz</h3>
						<p>
							A Tárgyi eszköz modul alkalmazható minden gazdasági szervezetnél a befektetett eszközök nyilvántartására,
							ideértve az immateriális javakat és a tárgyi eszközöket. A modul használatával lehetőség nyílik az
							immateriális és tárgyi eszközök eszközkartonjának teljes körű nyilvántartására, amelyben bármely gazdasági
							esemény nyomon követhető.
						</p>
						<h3>Értékpapír</h3>
						<p>
							A hitelintézetnek ezen modulon belül van lehetősége a forgatási célra, illetve a befektetési célra
							vásárolt értékpapírjainak a nyilvántartására. Ezenkívül a bankközi adott kölcsönöket is itt lehet
							nyilvántartani.
						</p>
						<h3>Főkönyv</h3>
						<p>
							A főkönyvbe az üzleti (számlavezetés, hitel, betét stb.) szoftverrészből, az üzemi (tárgyi, házipénztár
							stb.) szoftverrészből vagy külső rendszerből (pl. bér) interfész segítségével kerülhetnek adatok. A
							főkönyvi számok felépítése a piramiselven alapszik. A főkönyvi számok kirendeltségi és devizanemenkénti
							megbontása nem szükséges. Egy főkönyvi számon belül is kinyerhető minden adat a rendszerből.
						</p>
						<h3>Részjegy</h3>
						<p>
							A Részjegy modul képes sorszámozott vagy dematerializált részjegyek nyilvántartását is kezelni a
							beállított paraméterektől függően
						</p>
						<h3>Kimutatáskezelő</h3>
						<p>
							Integráltságának köszönhetően a rendszer adatszolgáltatási kötelezettség alá tartozó kimutatások
							készítésére is képes (például a számlaváltozás-jelentés, kimutatások az OBA részére). Teljeskörűen
							készíthetők a havi, negyedéves, féléves, éves jelentések is mind az analitikából, mind a főkönyvből A
							jelentések nyomtathatók, és TXT-fájlban továbbíthatók is. A továbbított jelentések archiválásra kerülnek,
							így bármikor lekérhetők.
						</p>
						<h3>Értesítések</h3>
						<p>A rendszer alkalmas sms-es és email-es értesítésre mind az ügyfelek mind a felhasználók részére.</p>
						<h3>Workflow</h3>
						<p>
							Az Instant Money folyamatai fel vanna fűzve a worfklow rétegre, így rugalmasan szabályozhatók a
							felhasználói beavatkozások és értesítések. Továbbá lehetőség van taszkok létrehozására, feladat
							kiosztására, függőségek és értesítések beállításra
						</p>
						<h3>Naplózások</h3>
						<p>
							Több szintű naplózással rendelkezik a rendszer. A felhasználói tevékenységektől kezdve a program
							használatáig a tényleges adatbázisok mező szintű változásáig történnek naplózások
						</p>
					</b-col>
				</b-row>
				<b-row class="justify-content-center align-items-center footer">
					<b-col cols="6">
						<b-img :src="imgLogo" class="footer__img" />
					</b-col>
					<b-col cols="6">
						<p>Copyright © {{ yearToday }} Techron Kft.</p>
						<a :href="`${publicPath}documents/adatkezelesi-tajekoztato.pdf`" target="_blank" class="link-privacy"
							>Adatkezelési Tájékoztató</a
						>
					</b-col>
				</b-row>
			</b-container>
		</section>
	</div>
</template>

<script>
import NavBar from './components/NavBar';
export default {
	data() {
		return {
			publicPath: process.env.BASE_URL,
			imgLogo: require('@/assets/images/logo.png'),
			yearToday: null,
		};
	},
	created() {
		this.actualYear();
	},
	methods: {
		actualYear() {
			var d = new Date();
			var n = d.getFullYear();
			this.yearToday = n;
		},
	},
	components: {
		NavBar,
	},
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 480px) {
	p {
		margin: 2em 0em !important;
		text-align: justify;
	}
	h2 {
		margin: 2em 0;
		font-size: 2em;
	}

	ul {
		list-style-type: circle;
		text-align: start !important;
		margin: 2em 1em !important;
	}
	.footer p {
		margin: 0px 0px !important;
	}
	h3 {
		font-size: 1.55rem;
		text-align: start;
		margin: 0em 0em !important;
	}
}

h3 {
	font-size: 1.55rem;
	text-align: start;
	margin: 0em 3em;
}

h2 {
	margin: 2em 0;
}
p {
	margin: 2em 5em;
	text-align: justify;
}

.footer p {
	margin: 0;
	text-align: center;
}

ul {
	list-style-type: circle;
	text-align: justify;
	margin: 2em 5em;
}
.footer__img {
	font-size: 0.5em;
}
.footer {
	margin-top: 5em;
}
.link-privacy {
	color: #2980b9;
}
.link-privacy:hover {
	color: #2980b9;
	text-decoration: none;
}
.instant-money-page {
	padding-top: 11em;
	background: #131421;
	min-height: 100vh;
}
.instant-money-section .container {
	background-color: #1f2131;
	color: #fff;
	padding: 20px;
	border-radius: 15px;
	font-size: 15px;
}
</style>
