<template>
	<b-navbar toggleable="lg" type="dark" fixed="top" class="products-menu">
		<b-container fluid>
			<div class="w-50 order-0">
				<ul class="navbar-nav">
					<li class="nav-item">
						<router-link :to="{ name: 'OnePage', hash: '#products' }">
							<b-icon
								icon="
arrow-left-short"
								class="back-icon"
							></b-icon>
						</router-link>
					</li>
				</ul>
			</div>
			<b-navbar-brand to="/">
				<b-img :src="imgLogo" class="brand__img" />
			</b-navbar-brand>
			<div class="navbar-collapse collapse dual-nav w-50 order-2"></div>
		</b-container>
	</b-navbar>
</template>

<script>
export default {
  data() {
    return {
      imgLogo: require("../../../assets/images/logo.png")
    };
  }
};
</script>

<style lang="scss" scoped>
.navbar {
	padding: 1rem;
	transition: 1s ease-in-out;
}

.brand__img {
	height: 30px;
}
.nav-item a {
	padding-right: 1em !important;
	padding-left: 1em !important;
}

.products-menu {
	background: #131421;
	/* border-bottom: 2px solid #2980b9; */
	box-shadow: 2.2px 2px 10px 0 #040406;
}

.nav-link {
	color: #fff !important;
	font-size: 1.5em;
	font-weight: bold;
	text-transform: uppercase;
}
.back-icon {
	font-size: 4em;
	color: #2980b9;
}
</style>
