<template>
	<div id="app">
		<router-view name="OnePage" class="onepage-bg-header"></router-view>
		<transition :name="transitionName">
			<router-view name="InstantMoney" class="instant-money-page"></router-view>
			<router-view name="AFR" class="afr-page"></router-view>
			<router-view name="InfoSafety" class="infoSafety-page"></router-view>
			<router-view name="Thanks" class="thanks-page"></router-view>
			<router-view name="Tender" class="tender-page"></router-view>
			<router-view name="Blog" class="blog-page"></router-view>
			<router-view name="ScopeErp" class="scope-page"></router-view>
		</transition>
	</div>
</template>

<script>
const DEFAULT_TRANSITION = 'fade';
export default {
	data() {
		return {
			prevHeight: 0,
			transitionName: DEFAULT_TRANSITION,
		};
	},
	created() {
		this.$router.beforeEach((to, from, next) => {
			let transitionName = to.meta.transitionName || from.meta.transitionName;

			if (transitionName === 'slide') {
				const toDepth = to.path.split('/').length;
				const fromDepth = from.path.split('/').length;
				transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
			}

			this.transitionName = transitionName || DEFAULT_TRANSITION;

			next();
		});
	},
};
</script>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
body {
	font-size: 10px !important;
	background-color: #131421 !important;
}
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
	transition-duration: 0.7s;
	transition-property: height, opacity, transform;
	transition-timing-function: cubic-bezier(0.55, 0, 0.5, 1);
	overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
	opacity: 0;
	transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
	opacity: 0;
	transform: translate(-2em, 0);
}
</style>
