<template>
	<div>
		<NavBar />
		<section class="afr-section">
			<b-container class>
				<b-row>
					<b-col>
						<h2>Azonnali Fizetési Rendszer</h2>
						<p class="title__text">
							A Techron Kft szakértő csapata egy, a hazai piacon egyedülálló fejlesztésű Azonnali Fizetési Rendszer
							csatlakozáshoz szükséges szoftvert fejlesztett le, amely révén a pénzügyi piac szereplői sokkal könnyebben
							és gyorsabban tudnak megfelelni a törvényi előírásoknak és tudják a szolgáltatást biztosítani ügyfeleik
							részére.
						</p>
						<h3>Megoldásunk</h3>

						<!--
            <ul class="gradient-list">
              <li>Middleware keretrendszer kialakítása, a sebesség, stabilitás és biztonság kialakítása céljából</li>
              <li>7/24/365 rendelkezésre állás</li>
              <li>Számla minimum díj kezelése. A számlákon lenne egy kötelezően fenntartandó összeg, amit nem vihet el az ügyfél a díjak teljesítése miatt.</li>
              <li>Az AFR réteg már díjjal együtt kapja meg a tranzakció összegét.</li>
              <li>Az árfolyamok feltöltése minden esetben a core rendszerben történik, majd egy üzeneten keresztül a middleware rétegben található árfolyamok szinkronizálásra kerülnek a core rendszer árfolyamaival.</li>
              <li>A tranzakció összegek foglalása előtt el kell végezni a pénzmosással összefüggő ellenőrzéseket, amelyek a szankció listák alapján történnek meg. A szankció listák feltöltése a core rendszerben történik, egy üzeneten keresztül a middleware rétegben található szankciós adatok szinkronizálásra kerülnek.</li>
              <li>Az AFR-ben lehetőség van másodlagos azonosítók felhasználásával megcímezni a kedvezményezettet (mobiltelefonszám, adóazonosító jel vagy adószám, elektronikus levelezési cím)</li>
              <li>A másodlagos azonosítók helyességéért egyértelműen a pénzintézet a felelős.</li>
              <li>Lehetőséget biztosítunk arra, hogy az utalások rögzítésekor a fizető fél meg tudjon adni másodlagos azonosítót is.</li>
              <li>Instant Money-ban, netbankon, applikációban indított utalásoknál a vizsgálat kiterjed a core rendszerben található adatokra, a fedezetre, a pénzmosási törvényre, viszont nem terjedhet ki a másodlagos azonosítók vizsgálatára.</li>
              <li>Az Instant Money-ban, netbankon, applikációban indított utalások esetén el kell tudnia dönteni a rendszernek, hogy melyik tranzakció esik bele az AFR hatályába és melyiket nem kell ezen a csatornán továbbítani.</li>
              <li>A GIRO által meghatározott formátumban és az előírt technológiával az üzeneteket továbbítjuk a GIRO felé majd fogadjuk, feldolgozzuk az érkezett válaszokat.</li>
              <li>A Giro lehetőséget ad olyan típusú (megállapodáson alapuló) üzenetek küldésére, amelyek a kedvezményezettől indulnak és amelyből a fizető fél képes azonnali átutalás indítására.</li>
              <li>Az AFR réteg minden esetben látja a számlák aktuális egyenlegét és a kártya autorizáció is innen történik.</li>
              <li>A tervdokumentáció tartalmazza a következőket: egyenlegkezelés, autorizáció, meglévő interfészek módosítása, azonnali tranzakciók kiszolgálása, paraméterezési lehetőségek, inkasszó kezelés.</li>
            </ul>

            -->

						<div class="puki">
							<div class="container-fluid blue-bg">
								<div class="container">
									<!--first section-->
									<div class="row align-items-center how-it-works">
										<div class="col-2 text-center bottom">
											<div class="circle">1</div>
										</div>
										<div class="col-6">
											<p>
												Middleware keretrendszer kialakítása, a sebesség, stabilitás és biztonság kialakítása céljából
											</p>
										</div>
									</div>
									<!--path between 1-2-->
									<div class="row timeline">
										<div class="col-2">
											<div class="corner top-right"></div>
										</div>
										<div class="col-8">
											<hr />
										</div>
										<div class="col-2">
											<div class="corner left-bottom"></div>
										</div>
									</div>
									<!--second section-->
									<div class="row align-items-center justify-content-end how-it-works">
										<div class="col-6 text-right">
											<p>7/24/365 rendelkezésre állás</p>
										</div>
										<div class="col-2 text-center full">
											<div class="circle">2</div>
										</div>
									</div>
									<!--path between 2-3-->
									<div class="row timeline">
										<div class="col-2">
											<div class="corner right-bottom"></div>
										</div>
										<div class="col-8">
											<hr />
										</div>
										<div class="col-2">
											<div class="corner top-left"></div>
										</div>
									</div>
									<!--third section-->
									<div class="row align-items-center how-it-works">
										<div class="col-2 text-center top">
											<div class="circle">3</div>
										</div>
										<div class="col-6">
											<p>
												Számla minimum díj kezelése. A számlákon lenne egy kötelezően fenntartandó összeg, amit nem
												vihet el az ügyfél a díjak teljesítése miatt.
											</p>
										</div>
									</div>
									<!--path between 3-4-->
									<div class="row timeline">
										<div class="col-2">
											<div class="corner top-right"></div>
										</div>
										<div class="col-8">
											<hr />
										</div>
										<div class="col-2">
											<div class="corner left-bottom"></div>
										</div>
									</div>
									<!--fourth section-->
									<div class="row align-items-center justify-content-end how-it-works">
										<div class="col-6 text-right">
											<p>Az AFR réteg már díjjal együtt kapja meg a tranzakció összegét.</p>
										</div>
										<div class="col-2 text-center full">
											<div class="circle">4</div>
										</div>
									</div>
									<!--path between 4-5-->
									<div class="row timeline">
										<div class="col-2">
											<div class="corner right-bottom"></div>
										</div>
										<div class="col-8">
											<hr />
										</div>
										<div class="col-2">
											<div class="corner top-left"></div>
										</div>
									</div>
									<!--fifth section-->
									<div class="row align-items-center how-it-works">
										<div class="col-2 text-center top">
											<div class="circle">5</div>
										</div>
										<div class="col-6">
											<p>
												Az árfolyamok feltöltése minden esetben a core rendszerben történik, majd egy üzeneten keresztül
												a middleware rétegben található árfolyamok szinkronizálásra kerülnek a core rendszer
												árfolyamaival.
											</p>
										</div>
									</div>
									<!--path between 5-6-->
									<div class="row timeline">
										<div class="col-2">
											<div class="corner top-right"></div>
										</div>
										<div class="col-8">
											<hr />
										</div>
										<div class="col-2">
											<div class="corner left-bottom"></div>
										</div>
									</div>
									<!--sixth section-->
									<div class="row align-items-center justify-content-end how-it-works">
										<div class="col-6 text-right">
											<p>
												A tranzakció összegek foglalása előtt el kell végezni a pénzmosással összefüggő ellenőrzéseket,
												amelyek a szankció listák alapján történnek meg. A szankció listák feltöltése a core rendszerben
												történik, egy üzeneten keresztül a middleware rétegben található szankciós adatok
												szinkronizálásra kerülnek.
											</p>
										</div>
										<div class="col-2 text-center full">
											<div class="circle">6</div>
										</div>
									</div>
									<!--path between 6-7-->
									<div class="row timeline">
										<div class="col-2">
											<div class="corner right-bottom"></div>
										</div>
										<div class="col-8">
											<hr />
										</div>
										<div class="col-2">
											<div class="corner top-left"></div>
										</div>
									</div>
									<!--seventh section-->
									<div class="row align-items-center how-it-works">
										<div class="col-2 text-center top">
											<div class="circle">7</div>
										</div>
										<div class="col-6">
											<p>
												Az AFR-ben lehetőség van másodlagos azonosítók felhasználásával megcímezni a kedvezményezettet
												(mobiltelefonszám, adóazonosító jel vagy adószám, elektronikus levelezési cím)
											</p>
										</div>
									</div>
									<!--path between 7-8-->
									<div class="row timeline">
										<div class="col-2">
											<div class="corner top-right"></div>
										</div>
										<div class="col-8">
											<hr />
										</div>
										<div class="col-2">
											<div class="corner left-bottom"></div>
										</div>
									</div>
									<!--eigth section-->
									<div class="row align-items-center justify-content-end how-it-works">
										<div class="col-6 text-right">
											<p>A másodlagos azonosítók helyességéért egyértelműen a pénzintézet a felelős.</p>
										</div>
										<div class="col-2 text-center full">
											<div class="circle">8</div>
										</div>
									</div>
									<!--path between 9-8-->
									<div class="row timeline">
										<div class="col-2">
											<div class="corner right-bottom"></div>
										</div>
										<div class="col-8">
											<hr />
										</div>
										<div class="col-2">
											<div class="corner top-left"></div>
										</div>
									</div>
									<!--ninth section-->
									<div class="row align-items-center how-it-works">
										<div class="col-2 text-center top">
											<div class="circle">9</div>
										</div>
										<div class="col-6">
											<p>
												Lehetőséget biztosítunk arra, hogy az utalások rögzítésekor a fizető fél meg tudjon adni
												másodlagos azonosítót is.
											</p>
										</div>
									</div>
									<!--path between 9-10-->
									<div class="row timeline">
										<div class="col-2">
											<div class="corner top-right"></div>
										</div>
										<div class="col-8">
											<hr />
										</div>
										<div class="col-2">
											<div class="corner left-bottom"></div>
										</div>
									</div>
									<!--tenth section-->
									<div class="row align-items-center justify-content-end how-it-works">
										<div class="col-6 text-right">
											<p>
												Instant Money-ban, netbankon, applikációban indított utalásoknál a vizsgálat kiterjed a core
												rendszerben található adatokra, a fedezetre, a pénzmosási törvényre, viszont nem terjedhet ki a
												másodlagos azonosítók vizsgálatára
											</p>
										</div>
										<div class="col-2 text-center full">
											<div class="circle">10</div>
										</div>
									</div>
									<!--path between 10-11-->
									<div class="row timeline">
										<div class="col-2">
											<div class="corner right-bottom"></div>
										</div>
										<div class="col-8">
											<hr />
										</div>
										<div class="col-2">
											<div class="corner top-left"></div>
										</div>
									</div>
									<!--eleventh section-->
									<div class="row align-items-center how-it-works">
										<div class="col-2 text-center top">
											<div class="circle">11</div>
										</div>
										<div class="col-6">
											<p>
												Az Instant Money-ban, netbankon, applikációban indított utalások esetén el kell tudnia dönteni a
												rendszernek, hogy melyik tranzakció esik bele az AFR hatályába és melyiket nem kell ezen a
												csatornán továbbítani.
											</p>
										</div>
									</div>
									<!--path between 11-12-->
									<div class="row timeline">
										<div class="col-2">
											<div class="corner top-right"></div>
										</div>
										<div class="col-8">
											<hr />
										</div>
										<div class="col-2">
											<div class="corner left-bottom"></div>
										</div>
									</div>
									<!--twelve section-->
									<div class="row align-items-center justify-content-end how-it-works">
										<div class="col-6 text-right">
											<p>
												A GIRO által meghatározott formátumban és az előírt technológiával az üzeneteket továbbítjuk a
												GIRO felé majd fogadjuk, feldolgozzuk az érkezett válaszokat.
											</p>
										</div>
										<div class="col-2 text-center full">
											<div class="circle">12</div>
										</div>
									</div>
									<!--path between 12-13-->
									<div class="row timeline">
										<div class="col-2">
											<div class="corner right-bottom"></div>
										</div>
										<div class="col-8">
											<hr />
										</div>
										<div class="col-2">
											<div class="corner top-left"></div>
										</div>
									</div>
									<!--thirteenth section-->
									<div class="row align-items-center how-it-works">
										<div class="col-2 text-center top">
											<div class="circle">13</div>
										</div>
										<div class="col-6">
											<p>
												A Giro lehetőséget ad olyan típusú (megállapodáson alapuló) üzenetek küldésére, amelyek a
												kedvezményezettől indulnak és amelyből a fizető fél képes azonnali átutalás indítására.
											</p>
										</div>
									</div>
									<!--path between 13-14-->
									<div class="row timeline">
										<div class="col-2">
											<div class="corner top-right"></div>
										</div>
										<div class="col-8">
											<hr />
										</div>
										<div class="col-2">
											<div class="corner left-bottom"></div>
										</div>
									</div>
									<!--fourteenth section-->
									<div class="row align-items-center justify-content-end how-it-works">
										<div class="col-6 text-right">
											<p>
												Az AFR réteg minden esetben látja a számlák aktuális egyenlegét és a kártya autorizáció is innen
												történik.
											</p>
										</div>
										<div class="col-2 text-center full">
											<div class="circle">14</div>
										</div>
									</div>
									<!--path between 14-15-->
									<div class="row timeline">
										<div class="col-2">
											<div class="corner right-bottom"></div>
										</div>
										<div class="col-8">
											<hr />
										</div>
										<div class="col-2">
											<div class="corner top-left"></div>
										</div>
									</div>
									<!--fifteenth section-->
									<div class="row align-items-center how-it-works">
										<div class="col-2 text-center top">
											<div class="circle">15</div>
										</div>
										<div class="col-6">
											<p>
												A tervdokumentáció tartalmazza a következőket: egyenlegkezelés, autorizáció, meglévő interfészek
												módosítása, azonnali tranzakciók kiszolgálása, paraméterezési lehetőségek, inkasszó kezelés.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
				<b-row class="justify-content-center align-items-center footer">
					<b-col cols="6">
						<b-img :src="imgLogo" class="footer__img" />
					</b-col>
					<b-col cols="6">
						<p>Copyright © {{ yearToday }} Techron Kft.</p>
						<a :href="`${publicPath}documents/adatkezelesi-tajekoztato.pdf`" target="_blank" class="link-privacy"
							>Adatkezelési Tájékoztató</a
						>
					</b-col>
				</b-row>
			</b-container>
		</section>
	</div>
</template>

<script>
import NavBar from "./components/NavBar";
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      imgLogo: require("@/assets/images/logo.png"),
      yearToday: null
    };
  },
  created() {
    this.actualYear();
  },
  methods: {
    actualYear() {
      var d = new Date();
      var n = d.getFullYear();
      this.yearToday = n;
    }
  },
  components: {
    NavBar
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 480px) {
	.container-fluid {
		padding-right: 0em !important;
	}
	.title__text {
		margin: 2em 2em !important;
	}
	.footer p {
		margin: 0px 0px !important;
	}
}

.footer__img {
	font-size: 0.5em;
}
.footer {
	margin-top: 5em;
}
.link-privacy {
	color: #2980b9;
}
.link-privacy:hover {
	color: #2980b9;
	text-decoration: none;
}
.title__text {
	margin: 5em 5em;
}
ul {
	list-style-type: circle;
	text-align: justify;
	margin: 5em 5em;
}
.afr-page {
	padding-top: 11em;
	background: #131421;
	min-height: 100vh;
}
.afr-section .container {
	background-color: #1f2131;
	color: #fff;
	padding: 20px;
	border-radius: 15px;
	font-size: 15px;
}

html,
body {
	font: 100% 'Lato', sans-serif;
	font-weight: 300;
	height: 100%;
	background-color: #4d4545;
}

.blue-bg {
	color: #2980b9;
	height: 100%;
}

.circle {
	font-weight: bold;
	padding: 15px 20px;
	border-radius: 50%;
	background-color: #2980b9;
	color: #4d4545;
	max-height: 50px;
	z-index: 2;
}

.how-it-works.row {
	display: flex;
}
.how-it-works.row .col-2 {
	display: inline-flex;
	align-self: stretch;
	align-items: center;
	justify-content: center;
}
.how-it-works.row .col-2::after {
	content: '';
	position: absolute;
	border-left: 3px solid #2980b9;
	z-index: 1;
}
.how-it-works.row .col-2.bottom::after {
	height: 50%;
	left: 50%;
	top: 50%;
}
.how-it-works.row .col-2.full::after {
	height: 100%;
	left: calc(50% - 3px);
}
.how-it-works.row .col-2.top::after {
	height: 100%;
	left: calc(50% - 0em);
}

.timeline div {
	padding: 0;
	height: 40px;
}
.timeline hr {
	border-top: 3px solid #2980b9;
	margin: 0;
	top: 17px;
	position: relative;
}
.timeline .col-2 {
	display: flex;
	overflow: hidden;
}
.timeline .corner {
	border: 3px solid #2980b9;
	width: 100%;
	position: relative;
	border-radius: 15px;
}
.timeline .top-right {
	left: 50%;
	top: -50%;
}
.timeline .left-bottom {
	left: -50%;
	top: calc(50% - 3px);
}
.timeline .top-left {
	left: -50%;
	top: -50%;
}
.timeline .right-bottom {
	left: 50%;
	top: calc(50% - 3px);
}
.text-right {
	text-align: start !important;
}

.row.align-items-center.how-it-works {
	text-align: start !important;
}
</style>
