<template>
	<div class="tenderContainer">
		<b-navbar toggleable="lg" type="light" fixed="top" :class="{ scroll__menu: isVisible }">
			<b-container>
				<b-navbar-brand v-scroll-to="'#top'">
					<b-img :src="imgLogo" class="brand__img" />
				</b-navbar-brand>
				<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

				<b-collapse id="nav-collapse" is-nav>
					<b-navbar-nav class="ml-auto">
						<b-nav-item class="tenderNavItem"
							><router-link :to="{ name: 'OnePage' }">Vissza a Főoldalra</router-link></b-nav-item
						>
					</b-navbar-nav>
				</b-collapse>
			</b-container>
		</b-navbar>
		<b-container>
			<h5>Grafikus Adat és Információ biztonsági szabályzati szolgáltatási rendszer fejlesztése KKV-k számára</h5>
			<div></div>
			<p>
				A projekt a Nemzeti Kutatási Fejlesztési és Innovációs Alapból biztosított támogatással, a Vállalatok K+F+I
				tevékenységének támogatása (Vállalati KFI_16) pályázati program finanszírozásában valósul meg.
			</p>
			<p>A projekt bemutatása</p>
			<p>
				A Techron Kft. tervezett Grafikus Adat és Információ biztonsági szabályzati szolgáltatási rendszer fejlesztése
				egy olyan információbiztonsági szabályzati rendszer, ami nem egy nehezen megérthető dokumentum-halmaz, hanem egy
				könnyen testre szabható grafikusan megjelenített szabályzati rendszer, amely bárhonnan biztonságosan, jelszóval
				védett webes környezetben elérhető szolgáltatás. Ennek a megoldásnak a segítségével a vállaltok egy széleskörűen
				paraméterezhető, testre szabható és minden információt tartalmazó információbiztonsági rendszert/szolgáltatást
				kapnak, amely mindenki számára könnyen elérhető és érthető. A tervezett Adat és Információ biztonsági
				szabályzati rendszer könnyen paraméterezhető, testre szabható grafikus megjelenítéssel, otthon végezhető
				munkafolyamatok támogatásával és ellenőrzésével, privát webes szolgáltatásként lesz elérhető. Az elmúlt
				időszakban az ismert körülmények miatt, megnőtt az otthoni munkavégzéssel kapcsolatos elvárások és feladatok
				számossága. Megfigyelve az eddigi helyzetet láthatjuk, hogy azon cégek, akik megfelelő, szabályozottsággal
				működtek, sokkal gyorsabban tudtak reagálni a kialakult helyzetre. A biztonságtudatosság az elmúlt években
				jutott el Magyarországon arra a szintre, hogy a vállalatvezetők ne kidobott pénzként, hanem biztosításként
				tekintsenek az információvédelemre fordított összegekre. Ahhoz, hogy minél több vállalat számára érthető és
				könnyen implementálható megoldást adjunk, egy teljesen újszerű megoldást valósítunk meg és teszünk elérhetővé.
			</p>
			<p>
				Mindezek által megnőtt annak az igénye, hogy minél több cég (kisebbek is) rendelkezzen információbiztonsági
				szabályzati rendszerrel és a meglevők is egyre inkább használják azokat és emellett egy biztonságos,
				ellenőrizhető otthoni környezetben is tudják végezni munkájukat. E mellett, második fő funkcióként a felhasználó
				cég munkatársai rögzíteni tudják a napi feladatokat, utazásokat és ebből vezetőség fele automatikus riportok
				készülnek, azaz a szolgáltatás egy kis- és középvállalkozásokra szabott, könnyen és gyorsan kezelhető
				vállalatirányítási rendszer munkafolyamat-irányítási (WF) és távmunkatámogató modulja.
			</p>
			<p>A munkafolyamat-irányítási rendszer céljai:</p>
			<ul class="textUl">
				<li>- Vezetői irányítás támogatása</li>
				<li>- Folyamatok automatizálása és státuszának nyomon követhetősége</li>
				<li>- Határidők tudatosítása a folyamatok végrehajtóiban</li>
			</ul>
			<footer>
				<b-row class="justify-content-center align-items-center">
					<b-col cols="6" class="text-left">
						<b-img :src="imgLogo" class="footer__imgTender" />
					</b-col>
					<b-col cols="6" class="text-right">
						<p>Copyright © {{ yearToday }} Techron Kft.</p>
					</b-col>
				</b-row>
			</footer>
		</b-container>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isVisible: false,
			imgLogo: require('@/assets/images/NKFIA.png'),
			yearToday: null,
		};
	},
	methods: {
		handleScroll() {
			if (window.pageYOffset < 55) {
				this.isVisible = false;
			} else {
				this.isVisible = true;
			}
		},
		actualYear() {
			var d = new Date();
			var n = d.getFullYear();
			this.yearToday = n;
		},
	},
	created() {
		window.addEventListener('scroll', this.handleScroll);
		this.actualYear();
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll);
	},
};
</script>

<style lang="scss" scoped>
.tender-page {
	background: white;
	min-height: 100vh;
}
.tenderContainer {
	text-align: left;
}

.brand__img {
	height: 10em;
	cursor: pointer;
}

a {
	color: black !important;
	font-size: 2em;
}

h5 {
	padding: 8em 0em 1.5em 0em;
	font-size: 4em !important;
	color: black !important;
}

p {
	font-size: 2em !important;
	color: black !important;
}

p:nth-child(odd) {
	padding: 1em 0em;
}

.textUl {
	font-size: 2em !important;
	color: black !important;
	padding: 1em;
	list-style-type: none;
}

.navbar-collapse.collapse.show {
	background: white;
}
.navbar {
	padding: 1rem;
	transition: 1s ease-in-out;
}

.ml-auto {
	margin-left: auto !important;
}

.scroll__menu {
	background: white;
	/* border-bottom: 2px solid #2980b9; */
	box-shadow: 2.2px 2px 10px 0 #040406;
	animation: menuMove 1s;
}

footer {
	background-color: white;
	padding: 2em 0;
	.link-privacy,
	.link-privacy:hover {
		color: #2980b9;
		text-decoration: none;
		font-size: 1.6em;
	}
	p {
		color: #fff;
		font-size: 1.6em;
		margin-bottom: 0.25em;
	}
}

.footer__imgTender {
	height: 10em;
}

@keyframes menuMove {
	from {
		top: 0px;
		border-bottom: none;
	}
	50% {
		top: -100px;
	}
	to {
		top: 0px;
	}
}

@media only screen and (max-width: 450px) {
	h5 {
		padding: 3em 0em 1.5em 0em;
	}

	.footer__imgTender {
		height: 5em;
	}

	a {
		color: black !important;
		font-size: 1.5em;
	}
}
</style>
