<template>
	<div class="blogContainer">
		<b-navbar toggleable="lg" type="light">
			<b-container>
				<b-navbar-brand>
					<b-img :src="imgLogo" class="brand__img" />
				</b-navbar-brand>
				<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

				<b-navbar-nav class="ml-auto">
					<b-nav-item class="blogNavItemWeb"
						><router-link :to="{ name: 'OnePage' }">Vissza a Főoldalra</router-link></b-nav-item
					>
				</b-navbar-nav>
				<b-nav-item class="blogNavItemMobile"
					><router-link :to="{ name: 'OnePage' }"> <b-icon icon="arrow-return-left"></b-icon> </router-link
				></b-nav-item>
			</b-container>
		</b-navbar>

		<div class="blogText">
			<h1>
				GINOP PLUSZ 1.2.1-21
			</h1>
			<h4>
				ERP rendszer, Informatikai biztonsági megoldások akár 70%-os támogatással!
			</h4>
			<p>
				Megjelent a GINOP PLUSZ 1.2.1-21 pályázat (korábbi nevén VINOP-1.2.1-21 pályázat), melynek egyik fő célja, hogy
				segítse a hazai vállalatok digitalizációját. A pályázat keretén belül nem csak informatikai biztonsági
				tanácsadás, vállalatirányítási rendszer, hanem többek között digitális eszköz, számítógép és üzleti
				felhőszolgáltatás is beszerezhető.
			</p>
			<p>
				A Techron Kft. Vállalatirányítási Rendszere és Informatikai biztonsági tanácsadás szolgáltatása teljes mértékben
				megfelel a pályázatba foglalt követelményeknek.
			</p>

			<h4>A támogatható tevékenységek köre:</h4>

			<ul>
				<li>Technológiafejlesztés</li>
				<li>Infrastrukturális és ingatlan beruházás</li>
				<li>Tanácsadási szolgáltatások igénybevétele</li>
				<li>Képzési szolgáltatások igénybevétele</li>
				<li>Üzleti felhőszolgáltatás igénybevétele</li>
				<li>Megújuló energiaforrást hasznosító technológiák alkalmazása</li>
				<li>Projekt-előkészítés</li>
			</ul>
			<h4>Az igényelhető támogatás összege:</h4>

			<p>
				A vissza nem térítendő támogatás összege minimum 10.000.000 Ft, maximum 629.300.000 Ft és a támogatási
				intenzitás mértéke 45-70%.
			</p>

			<h4>
				A pályázók köre
			</h4>

			<p>
				Jelen felhívásra támogatási kérelmet nyújthatnak be azon, Magyarország területén székhellyel rendelkező, kettős
				könyvvitelt vezető gazdasági társaságok, amelyek:
			</p>

			<ul>
				<li>rendelkeznek legalább egy lezárt, teljes üzleti évvel;</li>
				<li>átlagos statisztikai állományi létszáma az utolsó teljes, lezárt üzleti évben minimum 3 fő volt,</li>
				<li>és nem Budapest területén kívánják megvalósítani a projektet.</li>
			</ul>
			<h4>
				Határidő
			</h4>
			<p>
				Első ütemben a támogatási kérelmek benyújtásának időszaka: 2021.07.12. – 2021.07.19.
			</p>

			<h4>
				Magunkról
			</h4>

			<p>
				Cégünk 20 éves tapasztalattal rendelkezik informatikai rendszerek fejlesztésében, üzemeltetésében, valamint IT
				biztonsági folyamatok és megoldások biztosításában. Referenciáink között a kis- és középvállalkozások mellett
				megjelennek pénzintézetek és állami szervek is. Keressen minket és egy rövid konzultációt követően küldünk önnek
				árajánlatot. Használja ki a lehetőséget és pályázzon!
			</p>
			<b-button class="career_menu"
				><router-link :to="{ name: 'OnePage', hash: '#contact' }">Érdekel</router-link></b-button
			>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			imgLogo: require('../../assets/images/newlogo.png'),
		};
	},
};
</script>

<style lang="scss" scoped>
.blog-page {
	background: #131421;
}
.blogContainer {
	padding: 5em 10em;
}
.navbar-collapse.collapse.show {
	background: #131421;
}

.brand__img {
	height: 30px;
}

.blogText {
	background: hsla(0, 0%, 100%, 0.1) !important;
	border-radius: 22px !important;
	padding: 5em;
	margin: 5em;

	color: white;
}
.career_menu {
	padding: 1em;
	background: #2980b9;
	border-radius: 5em;
}

.career_menu:hover,
.career_menu:active {
	background-color: #2980b9;
}

.career_menu > a {
	color: white;
	font-size: 3em;
	padding: 0.5em 1em;
	background-color: none;
	border-color: none;
}

.blogNavItemWeb .nav-link > a,
.blogNavItemMobile .nav-link > a {
	color: #2980b9;
	font-size: 2.5em;
}
.blogNavItemMobile {
	display: none;
}

h1,
h4,
ul {
	text-align: justify;
}

h1 {
	font-weight: 500;
}
h4 {
	font-weight: bold;
	color: #033c59;
}

li {
	font-size: 2em;
}

p {
	text-align: justify;
	font-size: 2em;
	color: white;
}

@media only screen and (max-width: 900px) {
	.blogNavItemWeb .nav-link > a {
		font-size: 1.75em;
	}
}

@media only screen and (max-width: 768px) {
	.blogNavItemMobile {
		display: block;
	}

	.blogNavItemWeb {
		display: none;
	}

	.blogText {
		padding: 1em;
		margin: 1em;
	}
}

@media only screen and (max-width: 500px) {
	.blogContainer {
		padding: 1em 0em;
	}

	p {
		text-align: justify;
		font-size: 1.5em;
		color: white;
	}

	li {
		font-size: 1.25em;
	}

	h4 {
		font-size: 2em;
	}

	.blogText {
		padding: 1em;
		margin: 1em;
	}

	.career_menu > a {
		color: white;
		font-size: 2em;
		background: #2980b9;
		border-radius: 2em;
		padding: 0.5em 1em;
	}
}
</style>
