<template>
	<div class="scopeContainer">
		<b-navbar toggleable="lg" type="light">
			<b-container>
				<b-navbar-brand>
					<b-img :src="imgLogo" class="brand__img" />
				</b-navbar-brand>

				<b-navbar-nav class="ml-auto">
					<b-nav-item class="blogNavItemWeb"
						><router-link :to="{ name: 'OnePage' }">Vissza a Főoldalra</router-link></b-nav-item
					>
				</b-navbar-nav>
				<b-nav-item class="blogNavItemMobile"
					><router-link :to="{ name: 'OnePage' }"> <b-icon icon="arrow-return-left"></b-icon> </router-link
				></b-nav-item>
			</b-container>
		</b-navbar>
		<h1>SCOPE ERP</h1>
		<h2>EGYSÉGES VÁLLALATIRÁNYÍTÁSI RENDSZER</h2>
		<p>
			Az általunk kifejlesztett ERP Integrált Vállalatitárirányítási Rendszer PostgreSQL és Oracle adatkezelő alapon
			működik és nyújt megoldást ügyfeleinknek ügyvitellel és vállalatirányítással kapcsolatos problémáira. Moduláris
			felépítésű a fokozatos, lépésről-lépésre történő váltás érdekében.
		</p>
		<h2>Az ERP rendszerünk speciális tulajdonságai:</h2>
		<h4>Számlázási modul:</h4>

		<ul>
			<li>Mind szállítói, mind vevői oldalról kezeli a proforma számlákat</li>
			<li>Szállítói számla iktatás biztosított</li>
			<li>Szállítói számla összes válfajának (sztornó, helyesbítő, módosító) rögzítése biztosított</li>
			<li>Kezeli a fordított adózást, önadózást, ÁFA arányosítást, különbözeti ÁFA-t</li>
			<li>Pénzforgalmi tevékenységhez kötött ÁFA kezelése vevőre és szállítóra egyaránt</li>
			<li>Szállítói számlák a kapott számlaszáma mellett, egy belső, szigorú sorszámozású iktatószámot is kezelnek</li>
			<li>Emellett lehetőség van a feladás sorrendje szerinti sorszámot adni a számlának</li>
			<li>
				Szállítói számla élet folyamata: iktatás, utalványozás (jóváhagyás, hogy ki lehet fizetni), kontírozás, ezt
				követően átutalás (netbankban beolvasható formátumban rögzítjük szabványos formátumban az átutalást),
				bankkivonatot rögzítjük vagy beolvassuk több bank különböző formátumú kivonatát. Majd összepontozzuk a számlát,
				azaz megnézzük, hogy az adott számla ki lett-e fizetve, vagy csak rész- esetleg túlteljesítés történt-e
			</li>
			<li>Vevői számla: kontírozás (számla rögzítése) készpénzes fizetés vagy átutalás</li>
			<li>Fizetési értesítések, felszólítások, egyenleg értesítők kezelése</li>
			<li>Folyószámla kezelés</li>
			<li>
				Rendszer nagy rugalmasságot biztosít a számlák kiegyenlítésére: főkönyvi rendszerezés, kiegyenlítés, számla
				rendezés, kompenzálás
			</li>
			<li>
				Az ERP rendszer az ÁFA bevallás összes nyomtatvány típusát kezeli, ami beolvasható az ÁNYK programban. Szabadon
				paraméterezhető az ÁFA bevallás. az ÁFA bevallás kezeli az önrevíziót és a végleges zárásig akárhányszor
				előállítható
			</li>
			<li>
				Automatikusan kezeli az ÁFA eltérítést (teljesítés dátuma és az ÁFA időszak eltér), előleg-végszámla
				könyveléseket
			</li>
			<li>NAV online kommunikáció – adószám ellenőrzést is használjuk.</li>
		</ul>

		<h4>Főkönyvi modul:</h4>
		<ul>
			<li>Főkönyvi feladások sztornójának lehetősége</li>
			<li>Több dimenziós főkönyvi adatok (főkönyv, deviza, elemzőkód)</li>
			<li>Adatelemzéshez és adat osztályozáshoz használható elemzőkódok használata</li>
			<li>Átértékelés</li>
			<li>Árfolyamkülönbözet</li>
			<li>Főkönyvi kipontozás</li>
			<li>Általános kimutatás kezelő rendszer</li>
		</ul>

		<h4>Partner modul:</h4>
		<ul>
			<li>A teljes rendszerre vonatkozó egységes partner törzs</li>
			<li>Folyószámla kezelés partner oldalról, ami az összes kapcsolódó analitikát tartalmazza</li>
			<li>Analitika kapcsolatok rendezése (számla-számla, kiegyenlítés, főkönyvi rendezés)</li>
			<li>A partnerekhez generált számlák pdf formátumban visszakereshetőek</li>
		</ul>

		<h4>Bank és átutalás modul:</h4>
		<ul>
			<li>Bank kivonatok beolvasása és automatikus tétel beazonosítása több formátumban</li>
			<li>Bank kivonatok manuális rögzítési és kipontozási lehetősége</li>
			<li>Bank tételeknél kezeli a túlfizetés és visszatérítés fogalmát</li>
			<li>
				A számlákból lehet átutalási fájlokat készíteni, amelyeket a netbankban be lehet olvasni és tranzakciót lehet
				generálni
			</li>
			<li>Egy-több tételes átutalások készítése számlaszámonként</li>
			<li>Kötegelt átutalás feldolgozás</li>
			<li>Kivonatok zárásának és visszanyitásának a lehetősége</li>
		</ul>
		<h4>Tárgyi eszköz modul:</h4>
		<ul>
			<li>Szabadon paraméterezhető gazdasági események</li>
			<li>Lineáris és egyösszegű értékcsökkenés számítás</li>
			<li>Fejlesztési tartalék</li>
			<li>Beruházási kedvezmény</li>
			<li>Finoman hangolható eszköz csoportok kialakítása (jellemzők, kontírozás)</li>
			<li>TAO kezelés</li>
			<li>Értékcsökkenés szimuláció</li>
			<li>Futamidő módosítás</li>
			<li>Automatikus ÉCS számítás értékesítéskor és selejtezéskor</li>
			<li>Leltár</li>
			<li>Piaci értékelés</li>
			<li>Számlák kapcsolása a tárgyi eszközökhöz</li>
		</ul>

		<h4>Logisztikai modul:</h4>
		<ul>
			<li>Raktárak és raktárhelyek kezelése;</li>
			<li>Konszignációs raktárak kezelése;</li>
			<li>Kiszervezett központi raktári tevékenység irányítása és követése;</li>
			<li>Cikkcsoport és jellemző bontású cikknyilvántartás;</li>
			<li>Szállítók, szállítói cikkszám azonosítás és beszerzési árak naplózott nyilvántartása;</li>
			<li>Vevők és vevő szintű eladási ár megállapodások naplózott kezelése;</li>
			<li>Vonalkód alapú áruazonosítás raktári mozgások és leltározás esetén;</li>
			<li>Vevői rendelések kezelése, nyilvántartása;</li>
			<li>Közvetlen és konszignációs értékesítés vevői rendelés alapján;</li>
			<li>Értékesítés főraktárak, illetve konszignációs raktárak készlete alapján vevői rendelések szerint;</li>
			<li>Szállítói rendelések kezelése, nyilvántartása;</li>
			<li>Automatizált szállítói rendelés előkészítés vevői rendelések alapján;</li>
			<li>Automatizált kiszállítás szervezés betárolt szállítói rendelés alapján;</li>
			<li>Raktári mozgások mozgásnem szerinti nyilvántartása;</li>
			<li>Mozgásnemek korlátlan paraméterezhetősége, mozgásnem típusok szerint;</li>
			<li>Raktári bevét és bevét stornó típusú mozgások kezelése;</li>
			<li>Raktári kiadás és kiadás stornó típusú mozgások kezelése;</li>
			<li>Raktárak közötti automatikus átadás és átadás stornó típusú mozgások kezelése;</li>
			<li>Raktári készletek átminősítésére szolgáló mozgások kezelése;</li>
			<li>Raktári mozgások kezelése a konszignációs készletek felhasználására;</li>
			<li>Készletek többszintű nyilvántartásának lehetősége (Raktár/Rhely/Cikkszám /Sarzs/Lejárat/EÁr);</li>
			<li>Készlet érték nyilvántartása;</li>
			<li>Készletek FIFO/FEFO szerinti automatizált csökkenése kiszedés esetén;</li>
			<li>Készletek automatizált kiszedése vevői rendelések szerint;</li>
			<li>Készletek többszintű lekérdezése (vállalati, raktári és raktárhelyi bontás);</li>
			<li>Vevői számla tételek automatizált előkészítése a kiszállított vevői rendelések alapján;</li>
			<li>Központi raktári irányítás kiszervezett logisztikai tevékenység esetén;</li>
			<li>Raktári utasítások és visszaigazolások kezelése kiszervezett logisztikai tevékenység esetén;</li>
			<li>Leltározás vonalkód támogatásával és automatikus leltári mozgások generálása.</li>
		</ul>

		<h4>Általános információk:</h4>
		<ul>
			<li>Excelben kinyerhető formátumban lehet az adatokat elemezni</li>
			<li>A szükséges adatok bevitelét a Rendszer „kikényszeríti”, ezáltal kötelező (dátum, partner adatok stb.)</li>
			<li>Multidevizás</li>
			<li>
				A különböző állapotoknak köszönhetően jól illeszkedik a kialakított szabályzati környezetekhez (rögzítés
				állapot, teljesítés állapot, kiegyenlítés állapot státusza), „workflow” alapú működés
			</li>
			<li>
				A rendszer által osztott minden analitikus iktatószám felépítése testre szabható (például: év, cégnév, tárgyi
				eszköz, sorszám stb.)
			</li>
			<li>
				Core rendszer fölött egy integrációs és ütemező réteg került (amelyhez a middleware rétegen keresztül könnyen
				csatolható bármilyen más rendszer, például bérprogram), szabványos JSON alapú interfésze van a core rendszernek,
				és ütemezett feladat végrehajtására is alkalmas. Például: készletkezelés esetén jelez, ha a termék lejárati
				dátuma közelít
			</li>
			<li>A felhasználó minden tevékenysége naplózásra kerül</li>
			<li>A rendszer átadást követően könnyen testre szabható a felhasználó által</li>
			<li>Word formátumú sablon kezelés a nyomtatványoknál (számlakép, egyenlegközlő stb…)</li>
			<li>FIFO és átlag árfolyam kezelés</li>
			<li>AD authentikált jogosultsági rendszer</li>
			<li>Email küldési lehetőség a rendszerből</li>
		</ul>
	</div>
</template>

<script>
export default {
	data() {
		return {
			imgLogo: require('../../assets/images/scope.png'),
		};
	},
};
</script>

<style lang="scss" scoped>
.scope-page {
	background: #131421;
}
.scopeContainer {
	padding: 5em 20em;
	color: white;
}

.navbar-collapse.collapse.show {
	background: #131421;
}

.brand__img {
	height: 50px;
}

.blogNavItemWeb .nav-link > a,
.blogNavItemMobile .nav-link > a {
	color: #2980b9;
	font-size: 2em;
}
.blogNavItemMobile {
	display: none;
}

h4,
ul {
	text-align: justify;
}

h1 {
	font-weight: 500;
	padding-top: 1em;
	font-size: 10em;
}

h2 {
	padding-bottom: 1em;
	padding-top: 1em;
}
h4 {
	font-weight: bold;
	color: #033c59;
}

p {
	text-align: justify;
	font-size: 2em;
	color: white;
}

li {
	font-size: 2em;
}

@media only screen and (max-width: 1100px) {
	.blogNavItemWeb .nav-link > a {
		font-size: 1.5em;
	}

	.brand__img {
		height: 40px;
	}

	.scopeContainer {
		padding: 5em 10em;
	}
}

@media only screen and (max-width: 768px) {
	.blogNavItemMobile {
		display: block;
	}

	.blogNavItemWeb {
		display: none;
	}
	.scopeContainer {
		padding: 1em 2em;
	}
	.blogNavItemMobile .nav-link > a {
		font-size: 1em;
	}
}
</style>
